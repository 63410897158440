<!-- Main Homepage -->

    <!-- Header Component-->
    <app-header (loginEvent)='loginEvent($event)'></app-header>
    <ngx-spinner size="medium" type="ball-atom"></ngx-spinner>



    <ng-container>
        <div class="full-screen-style">
            <app-sidenav-wrapper [userLoggedIn]="userLoggedIn" [userRole]="userRole" [userName]="userName"></app-sidenav-wrapper>
        </div>
        
    </ng-container>
    




    <app-footer></app-footer>