<h4 class="heading-text">Features</h4>

<div class="table-container">

    <div style="overflow-x:auto;" *ngIf="onLoad">
        <table class="table">
            <thead>
                <tr>
                    <th class="feature-header">
                        {{product.name}} Features
                    </th>
                    <ng-container *ngIf="productVarients && productVarients.length>0; else emptyProductVarientsHeader">
                        <ng-container *ngFor="let item of productVarients ">
                                    
                            <th scope="" class="">
                                
                                <div class="card-header">
                                    <div class="price-header">
                                        <span>{{item.name ? item.name : ''}}</span>
                                    </div>
                                    <div class="price-description">
                                        <span>{{item.addOnText ? item.addOnText : ''}}</span>
                                    </div>
                                    <div class="price-details">
                                        <h5>
                                            ₹ {{item.priceList[0].price}}
                                        </h5>
                                        <span>
                                            {{item.priceList[0].priceType}}
                                        </span>
                                    </div>
                                    <button class="btn request-btn" (click)="requestQuote(item)">Request quote</button>
                                </div>
                            </th>
                        </ng-container>
                    </ng-container>
                    <ng-template #emptyProductVarientsHeader>
                        <ng-container>
                            <th scope="" class="">
                                <div class="card-header">
                                    <div class="price-header">
                                        <span>{{product.name ? product.name : ''}}</span>
                                    </div>
                                    <div class="price-description">
                                        <span></span>
                                    </div>
                                    <div class="price-details">
                                        <h5>
                                            ₹ {{product.priceList[0].price}}
                                        </h5>
                                        <span>
                                            {{product.priceList[0].priceType}}
                                        </span>
                                    </div>
                                    <button class="btn request-btn" (click)="requestQuote(product)">Request quote</button>
                                </div>
                            </th>
                        </ng-container>
                    </ng-template>
                    


                  </tr>
            </thead>
          
            <tbody>
                <ng-container *ngFor="let features of featureList">
                <tr>
                    <td class="feature-td">
                        <span (click)="onNavigateTo(features.hyperLinkURL)">
                            <span [ngClass]="(features.hyperLinkURL) ? 'feature-list-bold-link' : 'feature-list-bold'">
                                {{features.name ? features.name : ''}}
                            </span> 
                            <span [ngClass]="(features.hyperLinkURL) ? 'feature-list-link' : 'feature-list'" class="feature-list">{{features.description ? ': '+features.description : ''}}</span>
                            
                        </span>
                        
                    </td>
                    <ng-container *ngIf="productVarients && productVarients.length>0; else emptyProductVarients">
                        <ng-container *ngFor="let item of productVarients">
                            <ng-container *ngIf="item.featureListArray.includes(features._id) ; else notFoundBlock">
                                <td class="align-center">
                                    <span class="card-header">
                                        <img alt="" class="image-icon" src="../../../assets/icons/tickiconblue.svg">
                                    </span>
                                    
                                </td>
                            </ng-container>
                            <ng-template #notFoundBlock>
                                <td class="align-center">
                                    <span class="card-header">
                                        -
                                    </span>
                                    
                                </td>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                    <ng-template #emptyProductVarients>
                        <ng-container>
                            <td class="align-center">
                                <span class="card-header">
                                    <img alt="" class="image-icon" src="../../../assets/icons/tickiconblue.svg">
                                </span>
                                
                            </td>
                        </ng-container>
                    </ng-template>
   
                    
                </tr>
            </ng-container>
            </tbody>
          
        </table>
      </div>
</div>

   

