<div class="modal-container">
    <div class="modal-header">
        <div class="modal-top">
            <!-- <img alt="BackgroundGradient" src="../../../assets/images/BackgroundGradient.png"> -->
            <img class="altsyslogo-alert" alt="AltsysLogo" src="../../../assets/Realize/logos/RealizeLogo.svg"> 
            <h4>Login / Sign Up</h4>
        </div>
    </div>
    <!-- <div class="modal-body">
        Welcome to Skysecure
    </div> -->
    <div class="modal-footer">
        <button class="btn compare-btn" (click)="modalCLick('cancel')">Cancel</button>
        <button class="btn request-btn" (click)="modalCLick('login')">Login</button>
    </div>
</div>