<!-- <div class="contact-us-wrapper">
    <div class="contact-us-container">
        Sample
    </div>
</div> -->

<div class="contact-us-wrapper">
    <div class="card-container">
      <div class="user">
        <img id="userAvatar" src="../../../assets/icons/ConnectUsIllustration.svg" width="125px" height="125px" />
      </div>
      <div class="accountStats">
        <span class="text-header">
            Connect Us!
        </span>
        <span class="text-small">
            Connect with Our Skilled Experts and Find the Best Plan for You!
        </span>

        <div>
            <button class="btn call-btn" (click)="showBasicDialog()"> Get Free Call</button>
        </div>
      </div>
    </div>
</div>

<!-- Modal For Email -->
<!-- <h5>Basic</h5>
<p-button (click)="showBasicDialog()" icon="pi pi-external-link" label="Show"></p-button> -->
<!-- <p-dialog header="Header" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000"> -->
<p-dialog header="Get Free Call" [(visible)]="displayBasic" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">

    <div class="form-body">

      <div class="row">
        <div class="col-md-12">
          
          <div class="form-group">
            <label id="email-label" for="email">Email</label>
            <input type="email" name="email" id="email" placeholder="Enter your email" class="form-control" required>
          </div>
          
        
        </div>
      </div>

      <div class="row textarea-style">
				<div class="col-md-12">
					<div class="form-group">
						<label>Leave Message</label>
						<textarea  id="comments" class="form-control" name="comment" placeholder="Enter your comment here..."></textarea>
					</div>
				</div>
			</div>
    </div>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="displayBasic=false" label="Cancel"></p-button>
        <p-button class="btn-send" icon="pi pi-check" (click)="sendEmail()" label="Send Email" styleClass="p-button-text"></p-button>
        
    </ng-template>
</p-dialog>
