<div class="card-item d-flex flex-column justify-content-center align-items-center m-2 p-2">
    <div class="ellipse p-3">
        <!-- <img src="../../../assets/icons/Users.svg" alt="Angular Grid: Getting Started"> -->
        <img src="{{imageSrc}}">
    </div>
    <div class="j_Y">
        <span class="text-normal">
            {{name}}
        </span>
    </div>
</div>