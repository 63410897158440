<div class="toolbar-holder">

    <div class="menu-icon-holder">
        <span (click)="menuToogled()">
            <mat-icon class="mat-icon-size">menu</mat-icon>
        </span>
        
    </div>
    <div class="menu-holder">
        <mat-toolbar color="var(--primary)" class="toolbar">
            <button #categoriesMenu="matMenuTrigger" mat-button [matMenuTriggerFor]="aboveMenu">
                
                <div>
                    <span class="text-normal">CATEGORIES</span>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
                    
                
                
            </button>
                <!-- <mat-menu #aboveMenu="matMenu" yPosition="below">
                <ng-container *ngFor="let category of categories">
                    <button mat-menu-item>{{category.name}}</button>
                </ng-container>
                
                </mat-menu> -->
                
                <mat-menu #aboveMenu="matMenu" yPosition="below">

                    <button #matMenuTrigger="matMenuTrigger" mat-menu-item [matMenuTriggerFor]="softwares">
                        <div class="menu-width">
                            <span class="text-normal">Software</span>
                        </div>
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]=" hardwareCategories &&  hardwareCategories.length > 0 ? hardware : null">
                        <div class="menu-width">
                            <span class="text-normal">Hardware</span>
                        </div>
                    </button>
                    
                </mat-menu>
                

                <mat-menu #softwares="matMenu" xPosition="after">
                    <ng-container *ngFor="let category of softwareCategories">
                    <button (click)="goToProductsPageWithCategorySelection(category)" [matMenuTriggerFor]="category.subCategories.length>0 ? categoryItems : null"
                        mat-menu-item><span class="text-normal">{{category.name}}</span></button>
                        <mat-menu #categoryItems="matMenu" xPosition="after">
                        <ng-container *ngFor="let subCategory of category.subCategories">
                            <button (click)="goToProductsPageWithSubCategorySelection(category,subCategory)" mat-menu-item><span class="text-normal">{{subCategory.name}}</span></button>
                        </ng-container>
                        </mat-menu>
                    </ng-container>
                </mat-menu>
                
                <mat-menu #hardware="matMenu" xPosition="after">
                    <ng-container *ngFor="let category of hardwareCategories">
                        <button (click)="goToProductsPageWithCategorySelection(category)" [matMenuTriggerFor]="category.subCategories.length>0 ? categoryItems : null" mat-menu-item><span class="text-normal">{{category.name}}</span></button>
                        <mat-menu #categoryItems="matMenu" xPosition="after">
                            <ng-container *ngFor="let subCategory of category.subCategories">
                                <button (click)="goToProductsPageWithSubCategorySelection(category,subCategory)" mat-menu-item class="text-normal"><span class="text-normal">{{subCategory.name}}</span></button>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                </mat-menu>

                <!-- <mat-menu #categoryItems="matMenu" xPosition="after">
                    <ng-container *ngFor="let category of softwareCategories">
                        <button (click)="goToProductsPageWithCategorySelection(category)" mat-menu-item>{{category.name}}</button>
                    </ng-container>
                </mat-menu> -->

                

            <button mat-button [matMenuTriggerFor]="belowMenu">
                <span class="text-normal">BRANDS</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
                <!-- <mat-menu #belowMenu="matMenu" yPosition="below">
                <button mat-menu-item>Item 1</button>
                <button mat-menu-item>Item 2</button>
                </mat-menu> -->

                <mat-menu #belowMenu="matMenu" yPosition="below">
                    <ng-container *ngFor="let oem of oemList">
                        <button (click)="goToProductsPageByBrand(oem)" mat-menu-item class="text-normal"><span class="text-normal">{{oem.name}}</span></button>
                    </ng-container>
                </mat-menu>

            <!-- <button mat-button [matMenuTriggerFor]="compareProducts &&  compareProducts.length > 0 ? beforeMenu : null">
                <span class="text-normal">COMPARE PRODUCT</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
                <mat-menu #beforeMenu="matMenu" yPosition="below">
                <button mat-menu-item>Item 1</button>
                <button mat-menu-item>Item 2</button>
                </mat-menu>


            <button mat-button [matMenuTriggerFor]="offers &&  offers.length > 0 ? afterMenu : null">
                <span class="text-normal">OFFER</span>
                <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
                <mat-menu #afterMenu="matMenu" yPosition="below">
                <button mat-menu-item>Item 1</button>
                <button mat-menu-item>Item 2</button>
                </mat-menu> -->
        </mat-toolbar>

        <ngx-spinner size="medium" type="ball-atom"></ngx-spinner>
    </div>
</div>