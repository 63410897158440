<ng-container *ngIf="userLoggedInFlag">
  <span class="navigation-arrow" [ngClass]="(isExpanded ? 'arrow-position-long' : 'arrow-position-short')" (click)="menuExpansion(isExpanded, null)">
    <mat-icon mat-list-icon class="chevron-button">{{(isExpanded ? 'chevron_left' : 'chevron_right')}}
    </mat-icon>
  </span>
</ng-container>

<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" opened="isExpanded" [ngClass]="(userLoggedInFlag) ? '' : 'hide-nav'">
      
      
      <mat-nav-list [ngClass]="(isExpanded) ? 'nav-expanded' : ''">
  
        
        
        
        

        <!-- Dashboard Section -->
        <mat-list-item>
          <span class="icon-holder" (click)="navAction(isExpanded , 'dashboard')">
            <img src="../../../../assets/YellowChalk/DashboardIcon.svg" title="Dashboard" alt="Dashboard">
          </span>
          <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('dashboard')">
              <span class="text-style">
                Dashboard
              </span>
              <!-- <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon> -->
            </div>
        </mat-list-item>


        <mat-list-item (click)="navAction(isExpanded , 'setting')">
          <span class="icon-holder">
            <img src="../../../../assets/Realize/logos/icons/SettingsIcon.svg" title="Setting" alt="Setting">
          </span>
          <div *ngIf="isExpanded" class="menu-item">
              <span class="text-style">
                Setting
              </span>
              <!-- <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon> -->
            </div>
        </mat-list-item>

       
        <mat-list-item (click)="navAction(isExpanded , 'deployment')">
          <span class="icon-holder">
            <img src="../../../../assets/Realize/logos/icons/DeployIcon.svg" title="Deploy" alt="Deploy">
          </span>
          <div *ngIf="isExpanded" class="menu-item">
              <span class="text-style">
                Deploy
              </span>
              <!-- <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon> -->
            </div>
        </mat-list-item>

        <!-- Security Section -->
        <mat-list-item>
          <span class="icon-holder" (click)="menuExpansion(isExpanded , 'security')" >
            <img src="../../../../assets/YellowChalk/SecurityIcon.svg" title="Security" alt="Security">
          </span>
          <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('security')">
              <span class="text-style">
                Assess
              </span>
              <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon>
            </div>
        </mat-list-item>

        <!-- Security Sub Menu -->
        <ng-container *ngIf="securitySubMenu">
          <mat-list-item (click)="navAction(isExpanded , 'security')">
            <span *ngIf="isExpanded" class="sub-menu-item">Security</span>
          </mat-list-item>
          <mat-list-item (click)="navAction(isExpanded , 'recommendations')">
            <span *ngIf="isExpanded" class="sub-menu-item">Recommendations</span>
          </mat-list-item>
          <mat-list-item>
            <span *ngIf="isExpanded" class="sub-menu-item">Reference Architecture</span>
          </mat-list-item>
        </ng-container>

        <!-- Insurance Section -->
        <mat-list-item>
          <span class="icon-holder" (click)="navAction(isExpanded , 'insurance')">
            <img src="../../../../assets/YellowChalk/InsuranceIcon.svg" title="Insurance" alt="Insurance">
          </span>
          <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('user')">
              <span class="text-style">
                Cyber Insurance
              </span>
              <!-- <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon> -->
            </div>
        </mat-list-item>

        <!-- Cloud Section -->
        <mat-list-item>
          <span class="icon-holder" (click)="navAction(isExpanded , 'cloud')">
            <img src="../../../../assets/YellowChalk/CloudIcon.svg" title="Cloud" alt="Cloud">
          </span>
          <div *ngIf="isExpanded" class="menu-item" (click)="subMenuClick('user')">
              <span class="text-style">
                Cloud
              </span>
              <!-- <mat-icon mat-list-icon class="chevron-button">expand_more</mat-icon> -->
            </div>
        </mat-list-item>

        
        



      </mat-nav-list>
    </mat-sidenav>










    <!-- Content - rendered Content for the current router path into the <router-outlet>, e.g. /dashboard or /info-->
    <mat-sidenav-content>
      <div class="router-otlet-content">
        <router-outlet></router-outlet>
      </div>
      
    </mat-sidenav-content>
  
  </mat-sidenav-container>
