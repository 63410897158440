/* Angular Imports */
import { NgModule }                    from '@angular/core';
import {
  Routes,
  RouterModule }                       from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
/* Feature Imports */
import { InterfaceComponent }          from './interface.component';
import { AuthGuard } from 'src/shared/services/auth.guard';


/**
 * Define Interface Routing Instructions
 */
const INTERFACE_ROUTES: Routes = [

  {
    path: '',
    component: InterfaceComponent,
    canActivate: [AuthGuard],
    children: [
      {
        canActivate: [AuthGuard],
        path: '',
        loadChildren: () => import('../routes/deployment-view/deployment-view.module').then(m=>m.DeploymentViewModule)
      },
      {
        canActivate: [],
        path: 'user-profile',
        loadChildren: () => import('../routes/user-profile/user-profile.module').then(m=>m.UserProfileModule)
      },
      {
        canActivate: [],
        path: 'security-view',
        loadChildren: () => import('../routes/security-view/security-view.module').then(m=>m.SecurityViewModule)
      },
      {
        canActivate: [],
        path: 'category-view',
        loadChildren: () => import('../routes/category-view/category-view.module').then(m=>m.CategoryViewModule)
      },
     
      {
        canActivate: [],
        path: 'deployment-view',
        loadChildren: () => import('../routes/deployment-view/deployment-view.module').then(m=>m.DeploymentViewModule)
      },
      {
        canActivate: [],
        path: 'company',
        loadChildren: () => import('../routes/company/company.module').then(m=>m.CompanyModule)
      },
      {
        canActivate: [],
        path: 'connection-page',
        loadChildren: () => import('../routes/connection-page/connection-page.module').then(m=>m.ConnectionPageModule)
      }
      // ,
      // {
      //   canActivate: [AuthGuard],
      //   path: '',
      //   loadChildren: () => import('../routes/connection-page/connection-page.module').then(m => m.ConnectionPageModule)
      // },
    ]
  }

];


@NgModule({

  imports: [
    RouterModule.forChild(INTERFACE_ROUTES)
  ],

  exports: [
    RouterModule
  ]

})


export class InterfaceRoutingModule { }

/* Export Feature Components */
export const ROUTED_COMPONENTS = [
  InterfaceComponent
];
